
























































import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Task from '@/models/experiment/SuperTask';
import TaskService from '@/services/TaskService';
import SuperTask from '@/models/experiment/SuperTask';
import { Prop } from 'vue-property-decorator';

@Component
export default class TaskList extends mixins(Toasts) {
  protected tasks: SuperTask[] = [];
  protected pageSize = 10;
  protected currentPageNo = 1;
  @Prop({ required: true })
  protected experimentid!: number;

  mounted(): void {
    this.getAllTasks();
  }

  protected getAllTasks(): void {
    TaskService.getAllTasksForExperiment(this.experimentid).then((res) => {
      this.tasks = res;
    });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected editTask(task: Task) {
    this.$router.push('/task/admin/edit/' + task.experiment + '/' + task.id);
  }

  protected deleteTask(task: Task): void {
    if (confirm('Do you really want to delete the task ' + task.name + '?')) {
      this.setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      TaskService.deleteTask(task.id!)
        .then(() => {
          this.getAllTasks();
          this.setLoading(false);
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast(
              'Failed to delete',
              'Could not delete task. ' + backendError.response.data.message,
              'danger'
            );
          }
        });
    }
  }

  protected navigate(name: string): void {
    this.$router.push(name);
  }

  protected taskFields = [
    {
      key: 'order',
      label: 'Pos.',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'shortName',
      label: 'Shortname',
      sortable: true,
    },
    {
      key: 'maxPoints',
      label: 'Points',
      sortable: true,
    },
    {
      key: 'amountSubmissions',
      label: 'Submissions',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      sortable: false,
    },
  ];
}
