










































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { PossibleAction } from '@/auth/PossibleAction';
import { hasPermission } from '@/auth/AuthService';
import TaskService from '@/services/TaskService';
import ReviewTask from '@/models/experiment/ReviewTask';
import Project from '@/models/Project';
import ProjectService from '@/services/ProjectService';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import ReviewAssignmentService from '@/services/ReviewAssignmentService';

@Component
export default class AddReviewTask extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected task = new ReviewTask('', '', '', 0, 0, false, [], -1, -1);
  @Prop({ required: true })
  protected experimentid!: number;
  protected selectedProjectId = -1;
  protected selectedReviewAssignmentId = -1;

  protected allProjects: Project[] = [];
  protected allReviewAssignmentsForSelectedProject: ReviewAssignment[] = [];

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.setLoading(true);
      this.loadAllProjects();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected loadAllProjects(): void {
    ProjectService.getAll()
      .then((result) => {
        this.setLoading(false);
        this.allProjects = result;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load projects. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected loadAllReviewAssignments(): void {
    ReviewAssignmentService.getAllByProjectID(this.selectedProjectId)
      .then((result) => {
        this.setLoading(false);
        this.allReviewAssignmentsForSelectedProject = result;
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load review assignments. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected cancel(): void {
    this.$router.push({ path: '/experiment/admin/edit/' + this.experimentid });
  }

  protected saveTask(): void {
    if (this.selectedReviewAssignmentId !== -1) {
      this.task.reviewAssignment = this.selectedReviewAssignmentId;
      this.task.experiment = this.experimentid;
      this.setLoading(true);
      TaskService.saveReviewTask(this.task)
        .then(() => {
          this.setLoading(false);
          this.$router.replace({ path: '/experiment/admin/edit/' + this.experimentid });
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to save', 'Could not add task. ' + backendError.response.data.message, 'danger');
          }
        });
    } else {
      this.showToast('Failed to save', 'No review Assignment selected.', 'danger');
    }
  }
}
