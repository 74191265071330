




































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Experiment from '../../models/experiment/Experiment';
import ExperimentService from '@/services/ExperimentService';

@Component({})
export default class EditExperiment extends mixins(Toasts) {
  protected starttime = '';
  protected endtime = '';
  protected startdate = '';
  protected enddate = '';
  @Prop({ required: true })
  protected experiment!: Experiment;

  mounted(): void {
    this.starttime = new Date(this.experiment.start).toLocaleTimeString();
    this.starttime = this.starttime.substring(0, this.starttime.length - 3);
    this.endtime = new Date(this.experiment.end).toLocaleTimeString();
    this.endtime = this.endtime.substring(0, this.endtime.length - 3);
    this.startdate = this.experiment.start.substring(0, 10);
    this.enddate = this.experiment.end.substring(0, 10);
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected saveExperiment(experiment: Experiment): void {
    experiment.start = this.startdate;
    experiment.end = this.enddate;
    ExperimentService.saveExperiment(experiment, this.starttime, this.endtime)
      .then(() => {
        this.setLoading(false);
        this.$router.replace({ path: '/experiment/admin' });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to save',
            'Could not update experiment. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected cancel(): void {
    this.$router.push({ path: '/experiment/admin' });
  }
}
