


































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { PossibleAction } from '@/auth/PossibleAction';
import TaskService from '@/services/TaskService';
import GoogleFormsTask from '@/models/experiment/GoogleFormsTask';

@Component
export default class AddGoogleFormsTask extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected task = new GoogleFormsTask('', '', '', 0, 0, false, [], -1, '', '', '');

  @Prop({ required: true })
  protected experimentid!: number;

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected cancel(): void {
    this.$router.push({ path: '/experiment/admin/edit/' + this.experimentid });
  }

  protected saveTask(): void {
    this.task.experiment = this.experimentid;
    this.setLoading(true);
    TaskService.saveGoogleFormsTask(this.task)
      .then(() => {
        this.setLoading(false);
        this.$router.replace({ path: '/experiment/admin/edit/' + this.experimentid });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to save', 'Could not add task. ' + backendError.response.data.message, 'danger');
        }
      });
  }
}
